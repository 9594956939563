<template>
  <v-container class="login-form tw-bg-white tw-pt-24 tw-px-10">
    <div class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal tw-mb-4">
          Reset Password
        </h2>
        <p
          class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22 tw-w-4/6 tw-mx-auto"
        >
          We sent an email to
          <span class="tw-font-bold">{{ $route.params.email }}</span> with a
          link to create a new password.
        </p>
      </v-col>
    </div>
    <div>
      <button class="btn-tertiary tw-mt-56" @click="resendResetPassword">
        Re-Send Link
      </button>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "LinkSent",
  methods: {
    async resendResetPassword() {
      await this.$store.dispatch("create-password-reset/create", {
        email: this.$route.query.email || this.$route.params.email,
      });
      this.$store.dispatch("meta/showGlobalAlert", {
        type: "success",
        text: "Link was re-sent.",
        timeout: 2000,
      });
    },
  },
};
</script>
