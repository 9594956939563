<template>
  <v-container class="login-form tw-bg-white tw-px-10">
    <v-row class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
          Enter Password
        </h2>
        <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
          To start planning your next adventure.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-form @submit.prevent="login">
          <v-row>
            <v-col class="tw-px-0">
              <j-text-field
                color="secondary"
                ref="password"
                v-model="password"
                type="password"
                placeholder="Password"
                prepend-inner-icon="mdi-lock"
                rounded
                outlined
                :hide-details="errorOnAuthenticate.length === 0"
                :error-messages="errorOnAuthenticate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="tw-p-0">
              <v-btn
                rounded
                color="primary"
                block
                x-large
                class="tw-tracking-normal"
                type="submit"
                depressed
              >
                Login
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <div>
      <button class="btn-tertiary tw-mt-56" @click="resetPassword">
        Forgot your password?
      </button>
    </div>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
export default {
  name: "LoginForm",

  data() {
    return {
      password: null,
    };
  },

  computed: {
    errorOnAuthenticate() {
      if (
        this.$store.state.auth.errorOnAuthenticate?.message === "Invalid login"
      )
        return this.$store.state.auth.errorOnAuthenticate?.message;
      return "";
    },
  },

  methods: {
    async login() {
      let loginRes = await this.$store.dispatch("auth/authenticate", {
        email: this.$route.query.email || this.$route.params.email,
        password: this.password,
        strategy: "local",
      });

      if (this.$route.query.ref) {
        this.$router.push({ path: this.$route.query.ref });
      } else {
        if (loginRes && loginRes.user) {
          let data = loginRes.user;
          const now = DateTime.now();
          const start = DateTime.fromISO(data.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          if (count <= 7) {
            newUser = true;
          }
          window.dataLayer.push({
            event: "login",
            uuid: data.uuid,
            signup_date: DateTime.fromISO(data.createdAt).toISO(),
            new_user: newUser,
            sign_up_trip_id: data.signupTripId,
            verified_user: data.isEmailVerified,
            timestamp: DateTime.now().toISO(),
          });
        }
        this.$router.push({ name: "TripsRoute" });
      }
    },
    async resetPassword() {
      await this.$store.dispatch("create-password-reset/create", {
        email: this.$route.query.email || this.$route.params.email,
      });
      this.$router.push({
        name: "LinkSent",
        params: { email: this.$route.query.email || this.$route.params.email },
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.password.$parent.$el["1"].focus();
    });
  },
};
</script>

<style lang="scss">
.login-form .v-form .v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}

.login-form .v-btn__content {
  color: #2c3e50;
  text-transform: none;
}
</style>
