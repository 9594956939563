<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse"
  >
    <div class="tw-w-4/6 tw-mx-auto">
      <img
        src="@/assets/svgs/new_existing_sign_up_sign_in/send-link.svg"
        alt="Airplane"
        class="tw-mx-auto tw-mb-4"
      />
      <h2
        class="tw-text-lg tw-font-semibold tw-text-dark-green"
        v-html="title"
      ></h2>
      <p
        v-html="confirmationMessage"
        class="tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22"
      ></p>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-56 tw-w-full"
      >
        <v-btn
          :to="{ name: 'TripsRoute' }"
          exact
          block
          rounded
          x-large
          class="tw-tracking-normal"
          color="secondary"
        >
          I’ll verify later. Let’s go!
        </v-btn>
        <button @click="resendLink" class="btn-tertiary tw-mt-5">
          Re-Send Link
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      email: "",
      confirmationMessage: "",
    };
  },
  created() {
    this.email = this.$store.state.auth.user.email;
    this.title = "Almost There!";
    this.confirmationMessage = `We sent a confirmation link to <span class="tw-font-bold">${this.email}</span>. Click the link to confirm your email address.`;
  },
  methods: {
    async resendLink() {
      await this.$store
        .dispatch("user-verify-email-resend/create", {
          email: this.email,
        })
        .then(() => {
          this.title = "Re-sent!";
          this.confirmationMessage = `We’ve re-sent your link to <span class="tw-font-bold">${this.email}</span>. Be sure to check your promotions and other folders.`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
