<template>
  <div>
    <v-row class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
          Reset Password
        </h2>
        <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
          Please follow requirements below
        </p>
      </v-col>
    </v-row>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="submitPasswordReset">
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="password"
            rules="required|password"
          >
            <j-text-field
              color="secondary"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              :hide-details="errors.length === 0"
              :error-messages="errors"
              placeholder="Enter new password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :prepend-inner-icon="'mdi-lock'"
              @toggle="showPassword = !showPassword"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="password"
            vid="confirmation"
            rules="required|confirmed:confirmation"
          >
            <j-text-field
              color="secondary"
              v-model="confirmation"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              :hide-details="errors.length === 0"
              :error-messages="errors"
              placeholder="Confirm new password"
              :append-icon="
                showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
              "
              :prepend-inner-icon="'mdi-lock'"
              @toggle="showPasswordConfirmation = !showPasswordConfirmation"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <v-btn
            type="submit"
            :disabled="!passwordsMatch || invalid"
            x-large
            rounded
            block
            depressed
            class="j-btn tw-bg-chartreuse tw-tracking-normal"
            >Reset Password</v-btn
          >
        </v-col>
      </v-form>
    </validation-observer>
    <v-col class="mb-4">
      <PasswordDisclaimer />
    </v-col>
  </div>
</template>
<script>
import PasswordDisclaimer from "@/components/shared/PasswordDisclaimer.vue";

export default {
  data() {
    return {
      password: null,
      confirmation: null,
      showPassword: false,
      showPasswordConfirmation: false
    };
  },
  components: {
    PasswordDisclaimer
  },
  computed: {
    passwordsMatch() {
      // Check if both password and confirmation are not null and not empty strings
      if (
        this.password !== null &&
        this.confirmation !== null &&
        this.password !== "" &&
        this.confirmation !== ""
      ) {
        // Check if they match (type and value)
        return this.password === this.confirmation;
      }
      // If either is null or both are empty strings, return false
      return false;
    }
  },
  methods: {
    submitPasswordReset() {
      this.$store
        .dispatch("consume-password-reset/create", {
          uuid: this.$store.getters["consume-password-reset/list"][0].uuid,
          password: this.password
        })
        .then((res) => {
          this.login(res.email, this.password);
        });
    },
    async login(email, password) {
      await this.$store.dispatch("auth/authenticate", {
        email: email,
        password: password,
        strategy: "local"
      });

      if (this.$route.query.ref) {
        this.$router.push({ path: this.$route.query.ref });
      } else {
        this.$router.push({ name: "TripsRoute" });
      }
    }
  }
};
</script>
