<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse"
  >
    <div class="tw-w-3/6 tw-mx-auto">
      <img
        src="@/assets/svgs/new_existing_sign_up_sign_in/send-link.svg"
        alt="Airplane"
        class="tw-mx-auto tw-mb-4"
      />
      <h2 class="tw-text-lg tw-font-semibold tw-text-dark-green">
        Almost There!
      </h2>
      <p class="tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22">
        We re-sent an email to test@test.com with a link to create a new
        password.
      </p>
      <button class="btn-tertiary tw-mt-56">Re-Send Link</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "LinkResent",
};
</script>
