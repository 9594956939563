<template>
  <ul class="tw-text-left tw-text-light-grey tw-list-disc tw-text-xs">
    <li>Must contain at least 8 characters</li>
    <li>Must contain at least one Capital letter</li>
    <li>A combination of numbers and letters</li>
    <li>Use at least one special character (!$@%.)</li>
  </ul>
</template>
<script>
export default {
  name: "PasswordDisclaimer",
};
</script>
